var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.market.id)?_c('div',{staticClass:"cbo-wrapper wrapper--md-primary"},[_c('Container',[_c('div',{staticClass:"cbo-summary"},[_c('h1',{staticClass:"summary-title cbo-title-2"},[_vm._v(" "+_vm._s(_vm.$strings.processes_title)+" ")]),_c('router-link',{attrs:{"to":{ name: 'markets' }}},[_c('span',{staticClass:"summary-icon"},[_c('img',{attrs:{"src":_vm.market.logo,"alt":""}})]),_c('span',{staticClass:"summary-label"},[_vm._v(" "+_vm._s(_vm.market.name)+" ")])])],1)]),_c('div',{staticClass:"cbo-summary-angle"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $vuetify.icons.values.angle ")])],1),_c('Container',{staticClass:"cbo-process",attrs:{"white":"","full-height":""}},[_c('h2',{staticClass:"process-title"},[_vm._v(" "+_vm._s(_vm.$strings.processes_subtitle)+" ")]),_c('div',{staticClass:"process-list hidden-md-and-up"},[_c('carousel',{staticClass:"list-carousel",attrs:{"per-page":1,"pagination-enabled":false}},_vm._l((_vm.processesSelection),function(processus,i_processus){return _c('slide',{key:'processus_' + i_processus},[_c('router-link',{staticClass:"list-el",attrs:{"to":{
                name: 'problems',
                params: { market: _vm.market.id, processus: processus.id }
              }}},[_c('span',{staticClass:"el-picture"},[(processus.logo)?_c('img',{attrs:{"src":processus.logo.file,"alt":""}}):_vm._e()]),_c('span',{staticClass:"el-label"},[_vm._v(" "+_vm._s(processus.title)+" ")]),_c('span',{staticClass:"el-icon"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $vuetify.icons.values.angle ")])],1)])],1)}),1)],1),_c('div',{staticClass:"process-map hidden-sm-and-down"},[_c('div',{staticClass:"map-picture"},[(_vm.market.image_process)?_c('img',{attrs:{"src":_vm.market.image_process,"alt":""}}):_vm._e()]),_c('div',{staticClass:"map-pointers"},_vm._l((_vm.processesSelection),function(processus,i_processus){return _c('div',{key:'processus_' + i_processus,class:_vm.pointerClasses(processus.id),style:(_vm.pointerPositions(processus)),on:{"click":function($event){return _vm.switchPointer(processus.id)}}},[_c('router-link',{staticClass:"el-label",attrs:{"to":{
                name: 'problems',
                params: { market: _vm.market.id, processus: processus.id }
              }}},[_vm._v(" "+_vm._s(processus.title)+" ")]),_c('span',{staticClass:"el-icon"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $vuetify.icons.values.more ")])],1)],1)}),0)])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }