var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cbo-wrapper"},[_c('Container',[_c('div',{staticClass:"cbo-summary"},[_c('h1',{staticClass:"summary-title cbo-title-2"},[_vm._v(" "+_vm._s(_vm.$strings.problems_title)+" ")]),_c('router-link',{staticClass:"summary-market",attrs:{"to":{ name: 'markets' }}},[(_vm.market.logo)?_c('span',{staticClass:"market-icon"},[_c('img',{attrs:{"src":_vm.market.logo,"alt":""}})]):_vm._e(),_c('span',{staticClass:"market-label"},[_vm._v(" "+_vm._s(_vm.market.name)+" ")])]),(_vm.market && _vm.processus.id)?_c('div',{staticClass:"summary-breadcrumbs"},[_c('router-link',{attrs:{"to":{
            name: 'processes',
            params: { market: _vm.market.id }
          }}},[_vm._v(" "+_vm._s(_vm.processus.title)+" ")]),_vm._l((_vm.routeProblemsIds),function(problem_id,index){return _c('span',{key:'bc_problem_' + index},[(index % 2 === 0)?_c('span',{staticClass:"sep"},[_vm._v(">")]):_vm._e(),_c('router-link',{staticClass:"bc",attrs:{"to":{
              name: 'problems',
              params: { market: _vm.market.id, processus: _vm.processus.id }
            }}},[_vm._v(" "+_vm._s(_vm.getSubProblemName(problem_id))+" ")])],1)})],2):_vm._e()],1)]),_c('div',{staticClass:"cbo-summary-angle"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $vuetify.icons.values.angle ")])],1),_c('Container',{staticClass:"cbo-problems",attrs:{"white":""}},[_c('h2',{staticClass:"problems-title"},[_vm._v(" "+_vm._s(_vm.$strings.problems_subtitle)+" ")]),_c('v-row',{staticClass:"problems-list"},_vm._l((_vm.problemsSelection),function(problem,index){return _c('v-col',{key:'problem_' + index,staticClass:"pb-1",attrs:{"cols":"12","md":"6"}},[(problem.subProduits && problem.subProduits.length > 0)?_c('router-link',{staticClass:"list-el",attrs:{"to":{
            name: 'products',
            params: {
              market: _vm.market.id,
              processus: _vm.processus.id,
              problem: _vm.getProblemIds(problem.id)
            }
          }}},[_c('span',{staticClass:"el-button"},[_vm._v(_vm._s(problem.title))])]):_c('div',{class:['list-el', { 'el--active': _vm.open === problem.id }]},[_c('button',{staticClass:"el-button",on:{"click":function($event){return _vm.switchProblem(problem.id)}}},[_vm._v(" "+_vm._s(problem.title)+" "),_c('div',{staticClass:"el-icon"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $vuetify.icons.values.angle ")])],1)]),_c('div',{staticClass:"el-content"},[_c('div',{staticClass:"content-label",domProps:{"innerHTML":_vm._s(problem.question)}}),_c('div',{staticClass:"content-buttons"},_vm._l((problem.subProblemes),function(subproblem,s_index){return _c('Button',{key:'subproblem_' + s_index,attrs:{"to":{
                  name: _vm.getRouteName(subproblem),
                  params: {
                    market: _vm.market.id,
                    processus: _vm.processus.id,
                    problem: _vm.getProblemIds([problem.id, subproblem.id])
                  }
                }}},[_vm._v(" "+_vm._s(_vm.getSubProblemName(subproblem.id))+" ")])}),1)])])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }